/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */

import Gridworld from './gridworld';
import TabQ from './tabQ';

export default function sketch(p) {
    let cnv = p.createCanvas(2, 2);
    let sketchWidth = cnv.parent().offsetWidth
    console.log("sketchWidth" + sketchWidth);
    const numColumns = 5;
    const numRows = 5;
    const cellSize = Math.min(Math.max(20, (sketchWidth / numColumns)), 40);
    const boardHeight = numRows * cellSize;
    // const boardWidth = numColumns * cellSize;
    let gridworld;
    let bombImg;
    let goldImg;

    p.preload = () => {
        bombImg = p.loadImage('/../icons/bomb.png');
        goldImg = p.loadImage('/../icons/gold.png');
    };

    p.setup = () => {
        cnv = p.createCanvas(sketchWidth, boardHeight);
        p.frameRate(20);
        p.smooth();
        gridworld = new Gridworld(numRows, numColumns);
        p.textFont("Source Sans Pro");
        drawRewards();
        p.noLoop();
    };

    p.draw = () => {
        drawRewards();
        console.log("If in noLoop() mode, this should be only printed once for sGridworldRev");
    };

    function drawRewards() {
        p.textAlign(p.CENTER, p.CENTER);
        p.push();
        // p.translate();
        // p.stroke(0);
        p.textSize(14);
        for (let col = 0; col < numColumns; col += 1) {
            for (let row = 0; row < numRows; row += 1) {
                const ix = row * numColumns + col;
                p.fill(240);
                const x = col * cellSize;
                const y = boardHeight - (row + 1) * cellSize;
                p.rect(x, y, cellSize, cellSize);
                p.tint(255, 50);
                if (ix === gridworld.pcPositions[0]) {
                    p.image(goldImg, x + 2, y + 2, cellSize - 4, cellSize - 4);
                } else if (ix === gridworld.mrPositions[0]) {
                    p.image(bombImg, x + 2, y + 2, cellSize - 4, cellSize - 4);
                }
                p.fill(0, 0, 0);
                p.text(ix, x + cellSize / 2, y + cellSize / 2);
                // else if (ix === gridworld.currentState) {
                //     p.fill(230, 20, 20);
                //     p.circle(x + cellSize / 2, y + cellSize / 2, cellSize * 0.7);
                // }
            }
        }
    }
}
